import React from "react";
import { Moon, Sun } from "lucide-react"; // Import icons

const ThemeToggle = ({ id = "theme-toggle", className = "", theme, toggleTheme }) => {
  return (
    <div className={`vc-toggle-container ${className}`}>
      <input
        type="checkbox"
        id={id}
        className="vc-switch-input"
        checked={theme === "dark"}
        onChange={toggleTheme}
        aria-label="Toggle dark mode"
      />
      <label htmlFor={id} className="vc-switch">
        <span className="vc-handle">
          {theme === "dark" ? (
            <Moon className="icon moon-icon" size={16} />
          ) : (
            <Sun className="icon sun-icon" size={16} />
          )}
        </span>
      </label>
    </div>
  );
};

export default ThemeToggle;