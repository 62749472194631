import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useLanguage } from "../contexts/LanguageContext";
import ThemeToggle from "../pages/others/ThemeToggle";

const Header = ({showSecondNav, theme, toggleTheme}) => {
  const { language, switchLanguage } = useLanguage();
  const { t, i18n } = useTranslation();
  const [isMobileNavExpanded, setIsMobileNavExpanded] = useState(false);
  const [isBodyLocked, setIsBodyLocked] = useState(false);
  const [localStorageValue, setlocalStorageValue] = useState(
    localStorage.getItem("language") ?? "ar"
  );

  const mobileToggler = () => {
    setIsMobileNavExpanded(!isMobileNavExpanded);
    setIsBodyLocked(!isBodyLocked);
  };

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const handleSwitchLanguage = (lng) => {
    setlocalStorageValue(lng);
    switchLanguage(lng);
  };

  useEffect(() => {
    const localStorageLang = localStorage.getItem("language");
    if (localStorageLang) {
      changeLanguage(localStorageLang);
    }
  }, [localStorageValue]);

  useEffect(() => {
    document.documentElement.setAttribute("lang", i18n.language);
  }, [i18n.language]);

  return (
    <>
      <header className="main-header clearfix">	
      <nav className="main-menu clearfix">
        <div className="main-menu-wrapper clearfix">
          <div className="main-menu-wrapper__left">
            <div className="main-menu-wrapper__logo">
              <NavLink
                to="/"
                title="Go to AlRiyada Homepage"
                onClick={handleScrollToTop}
              >
                <img
                  className="header-logo"
                  src="/assets/img/alriyada-logo.svg"
                  width="160"
                  height="45"
                  alt="AlRiyada Logo"
                />
              </NavLink>
            </div>
            <div className="main-menu-wrapper__main-menu">
              <div className="d-flex justify-content-center align-items-center gap-3">
                <NavLink
                  type="button"
                  title="Go to AlRiyada Homepage"
                  className="mobile-nav__toggler"
                  onClick={mobileToggler}
                >
                  <i className="fa fa-bars"></i>
                </NavLink>
              </div>
              <ul className="main-menu__list">
                <li className="active">
                  <NavLink
                    to="/"
                    title="Go to AlRiyada Homepage"
                    onClick={handleScrollToTop}
                  >
                    {t("menu-1")}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/about"
                    title="Go to AlRiyada About Page"
                    onClick={handleScrollToTop}
                  >
                    {t("menu-2")}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/services"
                    title="Go to AlRiyada Services Page"
                    onClick={handleScrollToTop}
                  >
                    {t("menu-3")}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/contact"
                    title="Go to AlRiyada Contact Page"
                    onClick={handleScrollToTop}
                  >
                    {t("menu-4")}
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
          <div className="main-menu-wrapper__right">
            <div className="d-flex justify-content-center align-items-center gap-2">
              <div className="d-flex justify-content-center align-items-center gap-2">
                <button
                  className={`${localStorageValue === "en" ? "active" : ""}`}
                  onClick={() => handleSwitchLanguage("en")}
                >
                  <img
                    src="/assets/img/lang/flag-en.webp"
                    loading="lazy"
                    alt="AlRiyada English"
                  />
                </button>
                <button
                  className={`${localStorageValue === "ar" ? "active" : ""}`}
                  onClick={() => handleSwitchLanguage("ar")}
                >
                  <img
                    src="/assets/img/lang/flag-ar.webp"
                    loading="lazy"
                    alt="AlRiyada Arabic"
                  />
                </button>
              </div>
              <ThemeToggle id="main-header-theme-toggle" className="desktop-toggle" theme={theme}
                  toggleTheme={toggleTheme} />
            </div>
          </div>
        </div>
      </nav>
      </header>

      <div
        className={`sticky-header ${showSecondNav ? "sticked-menu" : ""}`}
        style={{
          transform: showSecondNav ? "translateY(0)" : "translateY(-100%)",
          transition: "transform 0.3s ease-in-out",
        }}
      >
          <div className="sticky-header__content">
            <nav className="main-menu clearfix">
            <div className="main-menu-wrapper clearfix">
              <div className="main-menu-wrapper__left">
                <div className="main-menu-wrapper__logo">
                  <NavLink to="/">
                    <img className="header-logo" src="/assets/img/alriyada-logo.svg" width="160" height="45" alt="Alriyada Logo" />
                  </NavLink>
                </div>
                <div className="main-menu-wrapper__main-menu">
                  <NavLink type="button" className="mobile-nav__toggler" onClick={mobileToggler}>
                    <i className="fa fa-bars"></i>
                  </NavLink>
                  <ul className="main-menu__list">
                    <li className="active">
                      <NavLink to="/" onClick={handleScrollToTop}>{t('menu-1')}</NavLink>
                    </li>
                    <li>
                      <NavLink to="/about" onClick={handleScrollToTop}>{t('menu-2')}</NavLink>
                    </li>
                    <li>
                      <NavLink to="/services" onClick={handleScrollToTop}>{t('menu-3')}</NavLink>
                    </li>
                    <li>
                      <NavLink to="/contact" onClick={handleScrollToTop}>{t('menu-4')}</NavLink>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="main-menu-wrapper__right">
                <div className="d-flex justify-content-center align-items-center gap-2">
                  <div className="d-flex justify-content-center align-items-center gap-2">
                    <button
                      className={`${localStorageValue === "en" ? "active" : ""}`}
                      onClick={() => handleSwitchLanguage("en")}
                    >
                      <img
                        src="/assets/img/lang/flag-en.webp"
                        loading="lazy"
                        alt="AlRiyada English"
                      />
                    </button>
                    <button
                      className={`${localStorageValue === "ar" ? "active" : ""}`}
                      onClick={() => handleSwitchLanguage("ar")}
                    >
                      <img
                        src="/assets/img/lang/flag-ar.webp"
                        loading="lazy"
                        alt="AlRiyada Arabic"
                      />
                    </button>
                  </div>
                  <ThemeToggle id="sticky-header-theme-toggle" className="desktop-toggle"  theme={theme}
                    toggleTheme={toggleTheme}/>
                </div>
              </div>
            </div>
            </nav>
          </div>
      </div>

      <div
        className={`mobile-nav__wrapper ${isMobileNavExpanded ? "expanded" : ""}`}
      >
        <div className="mobile-nav__overlay mobile-nav__toggler"></div>
        <div className="mobile-nav__content">
          <span
            className="mobile-nav__close mobile-nav__toggler"
            onClick={mobileToggler}
          >
            <svg viewBox="0 0 666.67 666.67">
              <path d="M33.33,666.67c-8.53,0-17.06-3.25-23.57-9.76-13.02-13.02-13.02-34.12,0-47.14l276.43-276.43L9.76,56.9C-3.25,43.89-3.25,22.78,9.76,9.76s34.12-13.02,47.14,0l276.43,276.43L609.77,9.76c13.02-13.02,34.12-13.02,47.14,0,13.02,13.02,13.02,34.12,0,47.14l-276.43,276.43,276.43,276.43c13.02,13.02,13.02,34.12,0,47.14s-34.12,13.02-47.14,0l-276.43-276.43L56.9,656.91c-6.51,6.51-15.04,9.76-23.57,9.76Z"/>
            </svg>
          </span>
          <div className="logo-box">
            <NavLink to="index.html" aria-label="logo image">
              <img
                src="/assets/img/alriyada-logo-w.svg"
                loading="lazy"
                width="160"
                height="45"
                alt="AlRiyada Logo"
              />
            </NavLink>
          </div>
          <div className="mobile-nav__container">
            <ul className="main-menu__list">
              <li className="active">
                <NavLink
                  to="/"
                  title="Go to AlRiyada Homepage"
                  onClick={mobileToggler}
                >
                  {t("menu-1")}
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/about"
                  title="Go to AlRiyada About Page"
                  onClick={mobileToggler}
                >
                  {t("menu-2")}
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/services"
                  title="Go to AlRiyada Services Page"
                  onClick={mobileToggler}
                >
                  {t("menu-3")}
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/contact"
                  title="Go to AlRiyada Contact Page"
                  onClick={mobileToggler}
                >
                  {t("menu-4")}
                </NavLink>
              </li>
            </ul>
          </div>
          <div className="mobile-menu-extras">
            <div className="d-flex align-items-center gap-4">
              <ThemeToggle id="mobile-header-theme-toggle" className="mobile-toggle" theme={theme} toggleTheme={toggleTheme} />
              <div className="d-flex justify-content-center align-items-center gap-2">
                <button
                  className={`${localStorageValue === "en" ? "active" : ""}`}
                  onClick={() => handleSwitchLanguage("en")}
                >
                  <img
                    src="/assets/img/lang/flag-en.webp"
                    loading="lazy"
                    alt="AlRiyada English"
                  />
                </button>
                <button
                  className={`${localStorageValue === "ar" ? "active" : ""}`}
                  onClick={() => handleSwitchLanguage("ar")}
                >
                  <img
                    src="/assets/img/lang/flag-ar.webp"
                    loading="lazy"
                    alt="AlRiyada Arabic"
                  />
                </button>
              </div>
            </div>
          </div>  
        </div>
      </div>

      {isBodyLocked && <style>{"body { overflow: hidden; }"}</style>}
    </>
  );
};

export default Header;
