import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n/i18n";

const MetaTags = () => {
  const { t } = useTranslation();

  return (
    <Helmet key={i18n.language}>
      <title>{t("meta.title")}</title>
      <meta name="description" content={t("meta.description")} />
      <meta name="author" content={t("meta.author")} />
      <meta name="keywords" content={t("meta.keywords")} />
    </Helmet>
  );
};

export default MetaTags;