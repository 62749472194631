import React from 'react';
import AlriyadaLoader from "../../assets/img/alriyada-loader.gif";
const Loader = () => (
  <div className="preloader">
    <img
      className="preloader__image"
      width="180"
      src={AlriyadaLoader}
      alt="AlRiyada Loader"
    />
  </div>
);

export default Loader;