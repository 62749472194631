import { Suspense, lazy, useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./commun/Header";
import Footer from "./commun/Footer";
import Gototop from "./pages/others/Scrolltop";
import "./App.css";
import "./assets/css/style.css";
import "./assets/css/responsive.css";
import "animate.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import smoothscroll from 'smoothscroll-for-websites';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-loading-skeleton/dist/skeleton.css'
import MetaTags from "./pages/others/MetaTags";
import WhatsappFloat from "./pages/others/WhatsappFloat";
import Loader from "./pages/others/Loader";

const Home = lazy(() => import("./pages/components/Home"));
const About = lazy(() => import("./pages/components/About"));
const Services = lazy(() => import("./pages/components/Services"));
const ServiceDetails = lazy(() => import("./pages/components/ServiceDetails"));
const Contact = lazy(() => import("./pages/components/Contact"));
const Page404 = lazy(() => import("./pages/components/Page404"));
// const Loader = <div className="preloader"><img className="preloader__image" width="180" src="/assets/img/alriyada-loader.gif" srcSet="/assets/img/alriyada-loader.gif 1x, /assets/img/alriyada-loader@2x.gif 2x, /assets/img/alriyada-loader@3x.gif 3x" alt="AlRiyada Loader" /></div>

function App() {
  const [showSecondNav, setShowSecondNav] = useState(false)
  const [isLoading, setIsLoading] = useState(true);
  const [theme, setTheme] = useState(() => {
    return localStorage.getItem("theme") || "light";
  });
  
  useEffect(() => {
    localStorage.setItem("theme", theme);
    document.documentElement.setAttribute("data-theme", theme);
  }, [theme]);

  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === "light" ? "dark" : "light"));
  };

  useEffect(() => {
		const handleScroll = () => {
      const headerScrollPos = 130;
      if (window.scrollY > headerScrollPos) {
        setShowSecondNav(true);
      } else {
        setShowSecondNav(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
      
    const initSmoothScroll = () => {
      smoothscroll({
        frameRate: 150,
        animationTime: 1200,
        stepSize: 100,
      });
    };

    if (document.readyState === "complete") {
      initSmoothScroll();
    } else {
      window.addEventListener("load", initSmoothScroll);
    }
  
    const loadResources = async () => {
      await new Promise((resolve) => setTimeout(resolve, 2000));
      setIsLoading(false);
    };

    loadResources();

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("load", initSmoothScroll);
    };

	}, [])
  
  return (
    <>
      <MetaTags />
      {isLoading ? (
        <Loader />
      ) : (
        <Suspense fallback={<Loader />}>
          <div className="page-wrapper">
            <Router>
              <main>
                <Header showSecondNav={showSecondNav} theme={theme} toggleTheme={toggleTheme} />
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/about" element={<About />} />
                  <Route path="/services" element={<Services />} />
                  <Route path="/services/:slug" element={<ServiceDetails />} />
                  <Route path="/contact" element={<Contact />} />
                  <Route path="*" element={<Page404 />} />
                </Routes>
              </main>
              <Footer />
              <Gototop />
            </Router>
            <WhatsappFloat />
          </div>
        </Suspense>
      )}
    </>
  );
}

export default App;
