import React from 'react'
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import { useTranslation } from 'react-i18next';


const WhatsappFloat = () => {
  const { t } = useTranslation();
  return (
    <FloatingWhatsApp
      phoneNumber='0096894040974'
      accountName={t('meta.author')}
      avatar='/assets/img/whatsapp-pic.png'
      statusMessage={t('meta.whatsappconnected')}
      chatMessage={t('meta.whatsappmessage')}
      darkMode='true'
      className = 'floating-whatsapp'
      chatboxHeight='400px'
      buttonClassName='floating-whatsapp-button'
      chatboxClassName ='floating-whatsapp-chatbox'
      placeholder={t('contact-form-message')}
      notification='false'
      allowEsc
      allowClickAway
    />
  )
}

export default WhatsappFloat