import React, { createContext, useContext, useState, useEffect } from "react";
import i18n from "../i18n/i18n";

const LanguageContext = createContext();

export const useLanguage = () => useContext(LanguageContext);

export const LanguageProvider = ({ children }) => {
  // Retrieve the saved language and direction from LocalStorage or use defaults
  const initialLanguage = localStorage.getItem("language") || "ar";
  const initialDirection = localStorage.getItem("direction") || "rtl";

  const [language, setLanguage] = useState(initialLanguage);
  const [direction, setDirection] = useState(initialDirection);

  // Update the document's direction attribute on app load
  useEffect(() => {
    document.documentElement.dir = initialDirection;
  }, [initialDirection]);

  const switchLanguage = (lang) => {
    // Determine the direction based on the language
    const dir = lang === "ar" ? "rtl" : "ltr";

    // Update states
    setLanguage(lang);
    setDirection(dir);

    // Save language and direction to LocalStorage
    localStorage.setItem("language", lang);
    localStorage.setItem("direction", dir);

    // Change the i18n language
    i18n.changeLanguage(lang);
    document.documentElement.lang = lang;

    // Refresh the page
    window.location.reload();
  };

  return (
    <LanguageContext.Provider value={{ language, direction, switchLanguage }}>
      <div dir={direction}>{children}</div>
    </LanguageContext.Provider>
  );
};
